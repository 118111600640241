import {
  useState, FormEvent, useEffect, useContext, SyntheticEvent,
} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '@ant-design/icons/CalendarOutlined';
import { NumericFormat } from 'react-number-format';
import LoadFileIcon from '@ant-design/icons/UploadOutlined';
import { Divider } from '@mui/material';
import ToolTip from '../../components/Tooltip';
import { GlobalContext } from '../../store';
import ProductSearch from '../../components/HomeComponents/ProductSearch';
import IReception, { IReceptionDetail } from '../../interfaces/IReception';
import uploadDocumentsToS3 from '../../helpers/uploadDocuments';
import capitalizeFirstLetter from '../../helpers/capitalizeFirstLetter';
import ConfirmDialog from '../../components/HomeComponents/ConfirmDialog';
import AlertDialog from '../../components/HomeComponents/AlertDialog';
import LoadingPage from '../../components/LoadingPageComponent';
import ILocal from '../../interfaces/ILocal';
import IReceptionProduct from '../../interfaces/IReceptionProduct';
import IProduct from '../../interfaces/IProduct';
import { IColumnData, IRowData } from '../../interfaces/INavigableTableObjects';
import SwitchButton from '../../components/HomeComponents/SwitchButton';
import { useTranslate } from '../../hooks';
import DropFile from '../../components/HomeComponents/DropFile';
import uploadCsvData from '../../helpers/uploadCsvData';
import downloadFileFromS3 from '../../helpers/downloadFileFromS3';
import NavigableTable from '../../components/ReceptionComponents/NavigableTable';

export default function ReceptionForm() {
  const { api, user } = useContext(GlobalContext);
  const [openModalAlert, setOpenModalAlert] = useState<boolean>(false);
  const [openConfirmProductAlert, setOpenConfirmProductAlert] = useState<boolean>(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const [confirmProducts, setConfirmProducts] = useState<string>('');
  const [modalAlertTitle, setModalAlertTitle] = useState<string>('');
  const [modalAlertText, setModalAlertText] = useState<string>('');
  const [selectedMfc, setSelectedMfc] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [isDocumentSelected, setIsDocumentSelected] = useState<boolean>(false);
  const [selectedDocumentNumber, setSelectedDocumentNumber] = useState<number>(0);
  const [documentName, setDocumentName] = useState<string>('');
  const [selectedDayTime, setSelectedDayTime] = useState<'am' | 'pm'>('am');
  const [locals, setLocals] = useState<ILocal[]>([]);
  const [allProducts, setAllProducts] = useState<IRowData[]>([]);
  const [productsToSend, setProductsToSend] = useState<IRowData[]>([]);
  const [dataToShow, setDataToShow] = useState<IRowData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [checkForProducts, setCheckForProducts] = useState<boolean>(false);
  const [switchUploadCSV, setSwitchUploadCSV] = useState(false);
  const [tableColumns, setTableColumns] = useState<string[]>([]);
  const [rowsValues, setRowsValues] = useState<unknown[]>([]);
  const [createItemsSubmitted, setCreateItemsSubmitted] = useState<boolean>(false);

  const translate = useTranslate('Page.ReceptionForm');

  // define data of columns
  const columns: IColumnData[] = [
    { title: translate('Columns.sku'), type: 'text', editable: false },
    { title: translate('Columns.quantity'), type: 'number', editable: true },
    { title: translate('Columns.expirationDate'), type: 'date', editable: true },
  ];
  const columnsShouldHave = [
    translate('Columns.sku').toLowerCase(),
    translate('Columns.quantity').toLowerCase(),
    translate('Columns.expirationDate').toLowerCase(),
  ];
  // Disable date of datepicker
  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6 && day !== 5;
  };
  // function to show text in modal alert
  function handleOpenModalAlert(errorType: number) {
    setOpenModalAlert(true);
    switch (errorType) {
      case 1:
        setModalAlertTitle(translate('Modal.errorTitle1'));
        setModalAlertText(translate('Modal.errorText1'));
        break;
      case 2:
        setModalAlertTitle(translate('Modal.errorTitle2'));
        setModalAlertText(translate('Modal.errorText2'));
        break;
      case 3:
        setModalAlertTitle(translate('Modal.errorTitle3'));
        setModalAlertText(translate('Modal.errorText3'));
        break;
      default:
        setModalAlertTitle(translate('Modal.successTitle'));
        setModalAlertText(translate('Modal.successText'));
        break;
    }
  }
  // next functions to update changed values in form
  function onChangeDate(date: Date) {
    setSelectedDate(date);
  }
  function onChangeDocument(e: FormEvent<HTMLLabelElement>) {
    let fileName = (e.target as HTMLInputElement).value;
    const { files } = e.target as HTMLInputElement;
    if (files && files?.length > 1) {
      setDocumentName(`${files?.length} ${translate('uploadFiles')}`);
    } else {
      const i = fileName.lastIndexOf('\\');
      fileName = fileName.substring(i + 1);
      setDocumentName(fileName);
    }
    setIsDocumentSelected(true);
  }
  function onChangeSelectedTime(value: 'am' | 'pm') {
    setSelectedDayTime(value);
  }
  function onChangeSelectedMfc(value: string) {
    setSelectedMfc(value);
  }
  function onChangeDocumentNumber(value: string) {
    setSelectedDocumentNumber(Number(value));
  }
  // function to clean all inputs after success in submitted form
  function cleanAllInputs() {
    dataToShow.forEach((product: IRowData) => {
      product.row[1].value = 0;
      product.row[2].value = undefined;
    });
    setDataToShow([]);
    setCreateItemsSubmitted(true);
    setProductsToSend([]);
    setSelectedDayTime('am');
    setDocumentName('');
    setSelectedDocumentNumber(0);
    setSelectedDate(undefined);
    setSelectedMfc('');
  }
  // function to check if data to be submit is correct
  function verifiedFormData() {
    if (
      selectedDocumentNumber !== 0
      && selectedMfc !== ''
      && isDocumentSelected
      && selectedDate
      && selectedDayTime
    ) { return true; }
    return false;
  }
  function updateData(products: IProduct[]) {
    const productsList = products.map((product: IProduct, index: number) => {
      const productToAdd: IReceptionProduct = {
        id: product.id,
        index,
        name: product.name,
        sku: product.sku,
        amount: 0,
        price: 1,
        expirationDate: product.needsLotTracking ? '01-01-2030' : null,
        needsLotTracking: product.needsLotTracking,
      };
      return productToAdd;
    });
    const list: IRowData[] = [];
    productsList.forEach((product: IReceptionProduct) => {
      const cellData = [
        { index: 0, value: `${product.sku}-${product.name}`, isDisable: false },
        { index: 1, value: product.amount, isDisable: false },
        { index: 2, value: product.expirationDate, isDisable: !product.needsLotTracking },
      ];
      const rowData: IRowData = { index: product.id, row: cellData };
      list.push(rowData);
    });

    list.sort((a, b) => a.index - b.index);

    setAllProducts(list);
    setDataToShow(list);
  }

  function handleUpdateSearch(newValue: string) {
    const selectedProductRows = dataToShow.filter((product) => product.row[0].value === newValue);

    const otherProductRows = dataToShow.filter((product) => product.row[0].value !== newValue);

    const newDataToShow = [...selectedProductRows, ...otherProductRows];

    setDataToShow(newDataToShow);
  }

  function toggleSwitchUploadCSV() {
    setSwitchUploadCSV(!switchUploadCSV);
    if (switchUploadCSV) {
      setDataToShow([]);
    }
    setCheckForProducts(false);
  }
  async function getLocalId() {
    return api.getLocalIdByName(selectedMfc);
  }

  // function to submit form
  function handleSubmit(e: SyntheticEvent) {
    e.preventDefault();
    setDisabledSubmitBtn(true);
    const products = dataToShow
      ?.filter((product) => Number(product.row[1].value) > 0);
    setProductsToSend(products);
    // check dates are correct
    const correctDates = products.reduce(
      (accum, product) => accum && (true && (product.row[2].isDisable
        || !!product.row[2].value)),
      true,
    );
    if (!correctDates) {
      handleOpenModalAlert(2);
      setDisabledSubmitBtn(false);
    } else if (verifiedFormData() && products.length > 0) {
      setOpenConfirmProductAlert(true);
    } else {
      handleOpenModalAlert(3);
      setDisabledSubmitBtn(false);
    }
  }
  useEffect(() => {
    (async () => {
      if (confirmProducts === 'true') {
        // Set data of reception
        setLoading(true);
        const receptionDetails: IReceptionDetail[] = [];
        const uploadedDoc = document.getElementById('DocumentToUpload') as HTMLInputElement;
        productsToSend.forEach((product: IRowData) => {
          let expirationDate = product.row[2].value as string;
          if (expirationDate) {
            let dateString = expirationDate.split('-');
            if (dateString.length === 1) {
              dateString = expirationDate?.split('/');
            }
            expirationDate = new Date(
              Number(dateString[2]),
              Number(dateString[1]) - 1,
              Number(dateString[0]),
            ).toISOString();
          }
          const receptionDetail: IReceptionDetail = {
            ProductId: product.index,
            amount: product.row[1].value as number,
            price: 1,
            expirationDate,
          };
          receptionDetails.push(receptionDetail);
        });
        const localId = await getLocalId();
        // Push file names
        const fileNames = [];
        const files = uploadedDoc.files as FileList;
        for (let i = 0; i < files?.length; i += 1) {
          let filename;
          if (files.length === 1) {
            filename = `${selectedDocumentNumber.toString().replaceAll('.', '')}-${user.username}.pdf`;
          } else {
            filename = `${selectedDocumentNumber.toString().replaceAll('.', '')}-${i + 1}-${user.username}.pdf`;
          }
          fileNames.push('https://clients-platform.s3.amazonaws.com/Operaciones/Formularios Envío Productos/Documento-'
            + `${filename}`);
        }
        const reception: IReception = {
          date: selectedDate!.toISOString(),
          dayTime: selectedDayTime,
          documentUrl: fileNames,
          documentNumber: selectedDocumentNumber,
          ClientId: user.connectableId,
          LocalId: localId,
          ReceptionDetails: receptionDetails,
        };
        try {
          // Post reception
          await api.createReception(reception);
          setLoading(false);
          cleanAllInputs();
          setOpenConfirmProductAlert(false);
          handleOpenModalAlert(0);
        } catch (error) {
          setLoading(false);
          setOpenConfirmProductAlert(false);
          handleOpenModalAlert(1);
          return;
        }
        try {
          // Upload reception document
          const url = 'Operaciones/Formularios Envío Productos/Documento-'
            + `${selectedDocumentNumber?.toString().replace('.', '')}`;
          uploadDocumentsToS3(user.username, url, uploadedDoc);
        } catch (error) {
          console.log(error);
        }
      }
    })();
    setConfirmProducts('');
    setDisabledSubmitBtn(false);
  }, [confirmProducts]);
  useEffect(() => {
    (async () => {
      // set locals to show in form
      const allLocalsData = await api.getLocals();
      setLocals(allLocalsData.locals);
      if (switchUploadCSV) {
        // load products one by one
        if (!checkForProducts) {
          setLoading(true);
          const result = await api.getClientProducts({ filter: { isPack: false } });
          const { products } = result;
          updateData(products);
          setLoading(false);
          setCheckForProducts(true);
        }
      }
    })();
  }, [allProducts, dataToShow]);
  useEffect(() => {
    // load product from csv
    (async () => {
      setLoading(true);
      const list: IRowData[] = [];
      const csvProducts = rowsValues.map((data) => (
        (data as string[])[0]
      ));
      const result = await api.getClientProducts({ filter: { rawSkus: csvProducts } });
      const { products } = result;
      // check if there are packs
      const checkIfPack = products.filter((prod) => prod.isPack);
      const productsNotFound = csvProducts.filter((string) => (
        !products.some((prod) => prod.rawSku === string)
      ));
      if (checkIfPack.length > 0) {
        setLoading(false);
        const packsSku = checkIfPack.map((prod: IProduct) => prod.rawSku).join(', ');
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.errorTitlePacks'));
        setModalAlertText(translate('Modal.errorTextPacks', { packsSku }));
      } else if (products.length < csvProducts.length && productsNotFound.length > 0) {
        setLoading(false);
        const strNotFoundSkus = productsNotFound.join(', ');
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.errorTitleCsv'));
        setModalAlertText(translate('Modal.errorTextCsv', { strNotFoundSkus }));
      } else {
        products.forEach((product) => {
          const dataToSubmit = rowsValues.filter((data) => (data as string[])[0] === product.rawSku);
          dataToSubmit.forEach((rowProduct) => {
            let expirationDate;
            if (product.needsLotTracking) {
              expirationDate = (rowProduct as string[])[2] === ''
                ? '01-01-2030' : (rowProduct as string[])[2];
            } else {
              expirationDate = null;
            }
            const cellData = [
              { index: 0, value: `${product.sku}-${product.name}`, isDisable: false },
              { index: 1, value: (rowProduct as string[])[1], isDisable: false },
              {
                index: 2,
                value: expirationDate,
                isDisable: !product.needsLotTracking,
              },
            ];
            const rowData: IRowData = { index: product.id, row: cellData };
            const productIndex = list.findIndex(
              ({ row: [rowSku, , rowExpirationDate] }) => rowSku.value === rowData.row[0].value
              && rowExpirationDate.value === rowData.row[2].value,
            );

            if (productIndex !== -1) {
              rowData.row[1].value = (Number(list[productIndex].row[1].value) + Number(rowData.row[1].value));
              list[productIndex] = rowData;
            } else {
              list.push(rowData);
            }
          });

          const blankCellData = [
            { index: 0, value: `${product.sku}-${product.name}`, isDisable: false },
            { index: 1, value: 0, isDisable: false },
            { index: 2, value: product.needsLotTracking ? '01-01-2030' : null, isDisable: !product.needsLotTracking },
          ];
          const blankRowData: IRowData = { index: product.id, row: blankCellData };
          const productIndex = list.findIndex(
            ({ row: [rowSku, , rowExpirationDate] }) => rowSku.value === blankRowData.row[0].value
            && rowExpirationDate.value === blankRowData.row[2].value,
          );

          if (productIndex === -1) {
            list.push(blankRowData);
          }
        });
        list.sort((a, b) => a.index - b.index);
        setDataToShow(list);
        setAllProducts(list);
        setLoading(false);
      }
    })();
  }, [tableColumns, rowsValues]);
  return (
    <div className="PageContainer">
      {loading ? (
        <LoadingPage />
      ) : (null)}
      <ConfirmDialog
        data={productsToSend}
        type="rowdata"
        description={translate('confirmDialog', { productsLength: productsToSend.length })}
        open={openConfirmProductAlert}
        setOpen={setOpenConfirmProductAlert}
        setConfirmData={setConfirmProducts}
      />
      <AlertDialog
        title={modalAlertTitle}
        text={modalAlertText}
        open={openModalAlert}
        setOpen={setOpenModalAlert}
      />
      <div className="FormMainContainer">
        <form
          className="Form"
          onSubmit={handleSubmit}
        >
          <div className="FormTitle">
            {translate('title')}
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('mfc')}
            </div>
            <select
              onChange={({ target: { value } }) => onChangeSelectedMfc(value)}
              className="selectInput"
            >
              <option
                selected={selectedMfc === ''}
                value=""
              >
                {translate('selectMfc')}
              </option>
              {locals?.map((local: ILocal) => (
                <option
                  key={local.id}
                  selected={selectedMfc === local.name}
                  value={local.name}
                >
                  {capitalizeFirstLetter(local.name)}
                </option>
              ))}
            </select>
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('receptionDate')}
            </div>
            <div className="InputForm AlignIcons">
              <CalendarIcon className="InputFormIcon" />
              <DatePicker
                dateFormat="d/MM/yyyy"
                selected={selectedDate}
                onChange={(date: Date) => onChangeDate(date)}
                minDate={new Date()}
                filterDate={isWeekday}
                excludeDates={[new Date(2022, 11, 29)]}
                className="WithDatePicker"
              />
              <select
                onChange={({ target: { value } }) => onChangeSelectedTime(value as 'am' | 'pm')}
              >
                <option
                  selected={selectedDayTime === 'am'}
                  value="am"
                >
                  {translate('receptionDateAM')}
                </option>
                <option
                  selected={selectedDayTime === 'pm'}
                  value="pm"
                >
                  {translate('receptionDatePM')}
                </option>
              </select>
            </div>
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel FormLabelDisplay">
              {translate('receptionDocument')}
              <ToolTip
                color="dark"
                text={translate('receptionDocumentTooltip')}
              />
            </div>
            <div className="InputForm AlignIcons">
              <div>
                {translate('receptionDocumentNumber')}
              </div>
              <NumericFormat
                className="InputFormText Margins ShortWidth"
                value={selectedDocumentNumber}
                allowNegative={false}
                decimalSeparator=","
                thousandSeparator="."
                onValueChange={(values) => onChangeDocumentNumber(values.value)}
              />
              <label
                className="InputFormDocumentUpload"
                onChange={onChangeDocument}
                htmlFor="DocumentToUpload"
              >
                <input
                  type="file"
                  id="DocumentToUpload"
                  accept="application/pdf"
                  hidden
                  multiple
                />
                <LoadFileIcon className="InputFormIcon" />
                <div>
                  {translate('receptionDocumentLoad')}
                </div>
              </label>
              <div className="InputFormDocumentUploadText">{documentName}</div>
            </div>
          </div>
          <Divider />
          <div className="FormContainer">
            <div className="FormLabel">
              {translate('uploadProducts')}
              {!switchUploadCSV ? (
                <div
                  className="UploadTutorialText"
                  onClick={
                    () => window.open(
                      'https://get-nomad.notion.site/Env-o-de-mercader-a-Masivo-31c9bf2350e5422d8b32762cb54826b6?pvs=4',
                      '_blank',
                    )
                  }
                >
                  {translate('bulkUploadTutorial')}
                </div>
              ) : (
                <div
                  className="UploadTutorialText"
                  onClick={
                    () => window.open(
                      'https://get-nomad.notion.site/Formulario-Env-o-de-Productos-manual-'
                      + '8292268782b249e99f9011d64eaf8e19?pvs=4',
                      '_blank',
                    )
                  }
                >
                  {translate('unitaryUploadTutorial')}
                </div>
              )}
            </div>
            <div className="FormSwitchUploadButtonContainer">
              <SwitchButton
                text={translate('loadOneByOne')}
                switchButton={switchUploadCSV}
                toggleSwitchBtn={toggleSwitchUploadCSV}
              />
              {!switchUploadCSV && (
                <div>
                  <DropFile
                    changeHandler={
                      (file: File) => uploadCsvData(
                        file,
                        columnsShouldHave,
                        setOpenModalAlert,
                        setModalAlertTitle,
                        setModalAlertText,
                        setTableColumns,
                        setRowsValues,
                        translate,
                      )
                    }
                    createItemsSubmitted={createItemsSubmitted}
                    setCreateItemsSubmitted={setCreateItemsSubmitted}
                  />
                  <div className="UploadItemsText">
                    {translate('download1')}
                    <div
                      onClick={() => downloadFileFromS3(
                        'Planillas/Ejemplo planilla creación recepción.csv',
                        'text/csv;charset=utf-8',
                      )}
                      className="UploadItemsTemplate"
                    >
                      {translate('download2')}
                    </div>
                    {translate('download3')}
                  </div>
                </div>
              )}
            </div>
          </div>
          {switchUploadCSV && (
            <>
              <Divider />
              <div className="FormContainer">
                <div className="FormLabel FormLabelDisplay">
                  {translate('searchProduct')}
                </div>
                <ProductSearch
                  onUpdateSearch={handleUpdateSearch}
                  allProducts={allProducts}
                  type="row"
                />
              </div>
            </>
          )}
          {dataToShow.length > 0 ? (
            <div className="FormContainerProducts">
              <NavigableTable
                dataToShow={dataToShow}
                setDataToShow={setDataToShow}
                columns={columns}
                loading={loading}
              />
            </div>
          ) : (null)}
          <div className="FormFooter">
            <button
              className="GoBackButton"
              type="submit"
              disabled={disabledSubmitBtn}
            >
              {translate('submitForm')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
