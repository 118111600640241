import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from '../../services';
import { ITracking } from '../../interfaces/ITracking';
import LoadingPage from '../../components/LoadingPageComponent';
import NotFound from '../NotFound';
import { useShipmentSubscription, useTranslate } from '../../hooks';
import TrackingBaseData from '../../components/TrackingComponents/TrackingBaseData';
import TrackingDeliveryInfoData from '../../components/TrackingComponents/TrackingDeliveryInfoData';
import TrackingDeliveryProofData from '../../components/TrackingComponents/TrackingDeliveryProofData';
import { Timeline } from '../../components/TrackingComponents/Timeline';
import TrackingMap from '../../components/TrackingComponents/TrackingMap';

export function TrackingOrder() {
  const publicAuthorization = process.env.REACT_APP_PUBLIC_TOKEN as string;
  const navigate = useNavigate();
  const translate = useTranslate('Tracking');
  const { internalCode } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const signature = queryParams.get('signature');
  const api = new Api(publicAuthorization);
  const [trackingData, setTrackingData] = useState<ITracking | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { i18n } = useTranslation('translation');
  const { shutdown } = useIntercom();

  useEffect(() => {
    shutdown();
    (async () => {
      setLoading(true);
      if (internalCode && signature) {
        try {
          const orderTracking = await api.getOrderTracking(internalCode, signature);
          setTrackingData(orderTracking);
        } catch (err) {
          setTrackingData(null);
        }
      }
      setLoading(false);
    })();
  }, []);

  const {
    shipmentTrackingData,
  } = useShipmentSubscription(publicAuthorization, trackingData?.order.internalCode);

  useEffect(() => {
    if (shipmentTrackingData && trackingData) {
      setTrackingData({
        ...trackingData,
        ...shipmentTrackingData,
      });
    }
  }, [shipmentTrackingData]);

  const mapOrderStatus = (): string => {
    if (trackingData?.shipment) {
      const { status } = trackingData.shipment;
      if (status.includes('Other status') || ['returned', 'lost'].includes(status)) {
        return translate('Status.verifying');
      }
      if (['cross-docking', 'ontheway', 'delivered', 'cancelled'].includes(status)) {
        return translate(`Status.${status}`);
      }
    }
    return trackingData?.order.status === 'fulfilled'
      ? translate('Status.fulfilled') : translate('Status.processing');
  };

  const generateDateString = (rawDate: string, shippingType?: string): string => {
    if (!rawDate) {
      return translate('DateString.soon');
    }
    const date = new Date(rawDate);
    const now = new Date();
    let dateText: string;
    if (date.getDate() === now.getDate()) {
      dateText = translate('DateString.today');
    } else if (date.getDate() - now.getDate() === 1) {
      dateText = translate('DateString.tomorrow');
    } else {
      const dateTextRaw = date.toLocaleDateString(i18n.language, { weekday: 'long', day: 'numeric', month: 'long' });
      dateText = dateTextRaw.charAt(0).toUpperCase() + dateTextRaw.slice(1);
    }
    const dateTimeText = date.toLocaleTimeString('it-IT').slice(0, -3);
    let dateRangeText: string;
    if (shippingType) {
      dateRangeText = ['pickup', 'courier'].includes(shippingType)
        ? translate('DateString.from') : translate('DateString.before');
    } else {
      dateRangeText = translate('DateString.at');
    }
    return `${dateText} ${dateRangeText} ${dateTimeText} hrs`;
  };

  return (
    <>
      {loading && (
        <LoadingPage full />
      )}
      {trackingData === null && !loading && (
        <NotFound />
      )}
      {trackingData && (
        <div className="TrackingOrderView">
          <div className="TrackingHeaderContainer">
            <img
              className="NomadHeaderLogo"
              alt="Nomad"
              src="https://nomad-assets-1.s3.amazonaws.com/public/NomadLogoBlack.png"
              onClick={() => navigate('/')}
            />
          </div>
          <div className="TrackingBodyContainer">
            <div className="TrackingBody">
              <div className="TrackingBodyRow HeaderRow">
                <div className="TrackingBodyColumn">
                  <TrackingBaseData
                    title={translate('orderNumber')}
                    text={trackingData.order.code}
                  />
                  <TrackingBaseData
                    title={translate('orderStatus')}
                    text={mapOrderStatus()}
                  />
                </div>
                <div className="TimelineColumn">
                  {!loading && (
                    <Timeline
                      data={[
                        {
                          fill: !!trackingData.order.statusHistory.fulfilled,
                          title: trackingData.local.name,
                        },
                        {
                          fill: (
                            !!trackingData.shipment?.statusHistory['cross-docking']
                              || !!trackingData.shipment?.statusHistory.ontheway
                          ),
                          title: translate('onDelivery'),
                        },
                        {
                          fill: !!trackingData.shipment?.statusHistory.delivered,
                          title: trackingData.order.addressString,
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
              {trackingData.order.shippingAddress.latitude
                && trackingData.order.shippingAddress.longitude
                && (
                  <div className="TrackingBodyRow MapRow">
                    {!['courier', 'same-day'].includes(trackingData.order.shippingType)
                      ? (
                        <TrackingMap
                          destiny={{
                            lat: trackingData.order.shippingAddress.latitude,
                            lng: trackingData.order.shippingAddress.longitude,
                          }}
                          origin={{ lat: trackingData.local.latitude, lng: trackingData.local.longitude }}
                          current={trackingData.shipment.status === 'ontheway' ? {
                            lat: trackingData.shipmentLocation.latitude,
                            lng: trackingData.shipmentLocation.longitude,
                          } : undefined}
                        />
                      ) : (
                        <TrackingMap
                          destiny={{
                            lat: trackingData.order.shippingAddress.latitude,
                            lng: trackingData.order.shippingAddress.longitude,
                          }}
                          zoom={15}
                        />
                      )}
                  </div>
                )}
              <hr className="SeparatorLine" />
              <div className="TrackingBodyRow">
                <div className="TrackingBodyColumn">
                  {trackingData.shipment && (
                    <TrackingBaseData
                      title={trackingData.shipment.statusHistory.delivered
                        ? translate('deliveredDate') : translate('eta')}
                      text={generateDateString(
                        trackingData.shipment.statusHistory.delivered
                          ? trackingData.shipment.statusHistory.delivered : trackingData.shipment.eta,
                        trackingData.shipment.statusHistory.delivered ? undefined : trackingData.order.shippingType,
                      )}
                    />
                  )}
                  <TrackingBaseData
                    title={translate('trackingNumber')}
                    text={trackingData.order.internalCode}
                  />
                  {trackingData.shipment && (
                    <TrackingDeliveryProofData shipment={trackingData.shipment} />
                  )}
                </div>
                <div className="TrackingBodyColumn">
                  <TrackingDeliveryInfoData order={trackingData.order} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
