export const getOrders = (limit: number, offset: number) => `
  query getOrders(
    $where: Orders_bool_exp!,
    $sorting: [Orders_order_by!]
  ) {
    Orders(limit: ${limit}, offset: ${offset}, where: $where, order_by: $sorting) {
      id
      date
      code
      platformCode
      customerEmail
      note
      shippingAddress
      customerName
      customerPhone
      status
      LocalId
      shippingType
      statusHistory
      shippingCost
      Local{
        name
      }
      OrderDetails {
        id
        ProductId
        price
        amount
        discount
      }
    }
    Orders_aggregate(where: $where) {
      aggregate {
        total:count
      }
    }
  }
`;
